<template>
	<main>
		<div class="header">
			<div class="title-box">
				<div class="btn" :class="[collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']" @click="toggleCollapse"/>
				<div class="title">{{title}}</div>
			</div>
			<div class="action-box">
				<div class="action">
					<div class="item" @click="toHome">进入首页</div>
				</div>
				<div class="user" @click="loginOut">
					<div class="avatar">{{avatar}}</div>
					<div class="name">{{ admin_info.nickname }}</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { auth} from "@/api/admin.js";
import {MessageBox} from "element-ui"
import {site_config} from "@/config/index.js";

export default {
	props:{
		collapse:false,
	},
	computed:{
		admin_info(){
			return this.$store.state.admin.admin_info
		},
		avatar(){
			return this.admin_info.username ? this.admin_info.username.split('')[0] : ''
		},
		title(){
			return `${site_config.title}管理系统`
		}
	},
	methods:{
		toHome(){
			const url = this.$router.resolve('/');
			window.open(url.href,'_blank')
		},
		loginOut(){
			MessageBox.confirm('确定要退出登录吗？','提示',{
				type:"warning"
			}).then((res)=>{
				auth.logout().then((res)=>{
					this.$store.commit('admin/setToken','')
					this.$store.commit('admin/setTags',[])
					this.$router.push('/admin/auth/login')
				})
			})
		},
		toggleCollapse(){
			this.$emit('toggleCollapse')
		}
	}
}
</script>

<style lang="scss" scoped>
.header{height:56px;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;
	.title-box{display: flex;align-items:center;
		.btn{width: 30px;height:30px;font-size: 20px;text-align: center;line-height: 30px;cursor: pointer;}
		.title{font-size: 20px;font-weight: bold;margin-left: 15px;color:$admin_main;}
	}
	.action-box{display: flex;align-items: center;
		.action{font-size: 14px;
			.item{cursor: pointer;}
		}
		.user{margin-left: 30px;display: flex;align-items: center;cursor: pointer;
			.avatar{width: 30px;height:30px;background: $admin_main;border-radius: 50%;font-size: 16px;color:#fff;text-align: center;line-height: 30px;text-transform: uppercase;}
			.name{margin-left: 10px;font-size: 16px;}
		}
	}
}
</style>

