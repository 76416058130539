<script >
import _ from "lodash";

export default {
	computed:{
		full_path(){
			return this.$route.fullPath
		},
		tags(){
			return this.$store.state.admin.tags
		}
	},
	watch:{
		$route:{
			handler(n){
				const index = this.tags.findIndex((item,index)=>{
					return item.path === n.fullPath
				})
				if(index < 0){
					const list = _.cloneDeep(this.tags);
					list.push({
						title:n.meta.title,
						path:n.fullPath
					})
					this.$store.commit('admin/setTags',list)
				}
			},
			immediate:true
		}
	},
	mounted() {
		this.swiperInit()
	},
	methods:{
		toLink(item){
			this.$router.push(item.path)
		},
		removeTag(index){
			const list = _.cloneDeep(this.tags);
			list.splice(index,1)
			this.$store.commit('admin/setTags',list)
			this.toLink(list[list.length - 1])
		},
		swiperInit(){
			const swiper = new Swiper("#swiper", {
				slidesPerView: "auto",
				spaceBetween: 10,
				observer:true,
				observeSlideChildren:true,
			});
		}
	}
}
</script>

<template>
	<div class="tag">
		<div ref="swiper" class="swiper" id="swiper" >
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in tags" :key="index" @click="toLink(item)" >
					<el-tag class="item" :effect="full_path === item.path ? 'dark' : 'plain'" :closable="!item.fixed" :disable-transitions="false" @close.stop="removeTag(index)">{{item.title}}</el-tag>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.tag{background: #fff;padding:7px 10px;
	.swiper-slide{width: auto;
		.item{cursor: pointer;}
	}
}
</style>
