<template>
	<main>
		<div class="menu-box" :class="[collapse ? 'mini' : '']">
			<div class="logo">
				<img :src="logo_src" alt="">
			</div>
			<div class="menu">
				<el-menu :collapse="collapse" :default-active="default_active" class="el-menu-vertical-demo" background-color="#000" text-color="#fff" >
					<template v-for="(item,index) in menu">
						<el-menu-item v-if="!item.children" :index="index+''" @click="toMenu(item)" @click.middle.native="toMenu(item,true)">
							<i :class="item.icon"></i>
							<span slot="title">{{item.title}}</span>
						</el-menu-item>
						<el-submenu v-else :index="index+''">
							<template slot="title">
								<i :class="item.icon"></i>
								<span slot="title">{{item.title}}</span>
							</template>
							<el-menu-item-group >
								<el-menu-item v-for="(v,i) in item.children" :key="i" :index="index+'-'+i" @click="toMenu(v)">{{ v.title }}</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
					</template>
				</el-menu>
			</div>
		</div>
	</main>
</template>

<script >
import {Message} from "element-ui";
import logo from "@/assets/admin/image/logo.png"
import logo2 from "@/assets/admin/image/logo_2.png"

export default {
	props:{
		menu:{
			default:[],
			type:Array
		},
		collapse:false
	},
	computed:{
		logo_src(){
			return this.collapse ? logo2 : logo
		},
		default_active(){
			const route_path = this.$route.fullPath;
			const index =  this.menu.findIndex((item,index)=>{
				return item.path === route_path;
			})
			return index.toString();
		}
	},
	methods:{
		toMenu(item,target = false){
			if(!item.path){
				Message.error('暂无页面');return false;
			}
			if(target){
				const route = this.$router.resolve(item.path);
				window.open(route.href,'_blank')
			}else{
				this.$router.push(item.path);
			}
		}
	}
}

</script>

<style lang="scss" scoped>
.menu-box{width: 200px; height:100vh;background: #000;transition: all .2s ease;overflow: hidden;overflow-y: auto;
	.logo{height:56px; display: flex;justify-content: center;align-items: center;
		img{height:30px;}
	}
	.el-menu{border-right: none;}
}
.menu-box.mini{width: 64px;transition: all .2s ease;}
</style>
