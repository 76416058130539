<template>
	<div id="app">
		<div class="menu">
			<components-menu :menu="menu" :collapse="collapse"/>
		</div>
		<div class="app-content">
			<div class="app-header">
				<components-header :collapse="collapse" @toggleCollapse="toggleCollapse"/>
			</div>
			<div class="app-container">
				<div class="app-tags">
					<components-tags/>
				</div>
				<div class="content">
					<transition name="fade">
						<router-view v-if="isDev" :key="route_key"></router-view>
						<keep-alive v-else>
							<router-view :key="route_key"></router-view>
						</keep-alive>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentsHeader from "@/components/layout/admin/Header.vue"
import ComponentsMenu from "@/components/layout/admin/Menu.vue"
import ComponentsTags from "@/components/layout/admin/Tags.vue"
import {auth} from "@/api/admin";
import {site_config} from "@/config";
import {isDev} from "@/common/other";
import "@/plugins/admin"
export default {
	components:{ComponentsHeader,ComponentsTags,ComponentsMenu},
	data(){
		return {
			collapse:false,
			menu:[
				{title:"控制台", icon:'el-icon-odometer', path:'/admin'},
				{title:"官网", icon:'el-icon-tickets',
					children:[
						{title:"案例管理",path:'/admin/home/cases'},
						{title:"合作伙伴",path:'/admin/home/partner'},
						{title:"文章管理",path:'/admin/home/article'},
						{title:"留言管理",path:'/admin/home/message'},
					]
				},
				{title:"开放接口", icon:'el-icon-tickets',
					children:[
						{title:"微信公众号",path:'/admin/open/wechat'},
					]
				},
				{title:"哨兵监控", icon:'el-icon-tickets',
					children:[
						{title:"账号管理",path:'/admin/sentry/account'},
					]
				},
				{title:"用户管理", icon:'el-icon-tickets',
					children:[
						{title:"用户管理",path:'/admin/user'},
					]
				},
				{title:"配置管理", icon:'el-icon-tickets',
					children:[
						{title:"系统配置",path:'/admin/config'},
						{title:"邮件模板",path:'/admin/email/template'},
					]
				},
				{title:"管理员",icon:'el-icon-user',path:'/admin/admin'},
			]
		}
	},
	computed:{
		route_key(){
			return this.$route.fullPath
		},
		meta(){
			return this.$route.meta;
		}
	},
	watch:{
		$route(){
			this.$store.commit('admin/setError',{})
		}
	},
	created() {
		document.title = `${site_config.title}管理系统`
		this.getAdminInfo();
	},
	methods:{
		isDev,
		toggleCollapse(){
			this.collapse = !this.collapse;
		},
		getAdminInfo(){
			auth.getLoginInfo().then((res)=>{
				this.$store.commit('admin/setAdminInfo',res.admin)
			})
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/admin/css/reset.scss';
@import '@/assets/admin/css/ricktext.scss';
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {transition: all .3s ease;}
.fade-enter-from, .fade-leave-to {opacity: 0;transition: all .3s ease;}
#app{position: relative;min-height: 100vh;display: flex;
	.app-content{flex: 1;overflow: hidden;
		.app-tags{margin-bottom: 15px;}
		.app-container{padding: 15px;background: #efefef;height:calc(100vh - 56px);overflow: hidden;overflow-y: auto;
			.content{background: #fff;padding: 15px;}
		}
	}
}
</style>
